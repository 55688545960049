/* ==========================================================================
Section Styles
========================================================================== */

//Sections
.section {
    &.section-light-grey {
        background-color: $light-grey;
    }
    &.section-feature-grey {
        background-color: $section-grey;
    }
    &.section-secondary {
        background-color: $secondary;
    }
    &.section-half {
        height: 75vh !important;
    }

    .section-title {
        color: $secondary;
    }

    .title, .subtitle {
        font-family: 'Open Sans', sans-serif;

    }
    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
}

.section.reasons {
    .has-huge-text {
        font-size: 200px;
        line-height: 100px;
        padding-top: 50px;
        padding-left: 50px;
    }
    .columns {
        .column>div {
            padding: 30px;
        }
    }
}

.section.contact {
    .content-wrapper {
        padding-bottom: 20px;
    }

    img.map {
        width: 100%;
        vertical-align: bottom;
    }

    .links img {
        margin: 0px 5px 0 30px;
        vertical-align: text-bottom;
        height: 18px;
    }

    .links a {
        color: white;
    }

    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

//Titles
.title-wrapper {
    max-width: 600px;
    margin: 0 auto;
    .title, .subtitle {
        font-family: 'Open Sans', sans-serif;

    }
    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
}

//Divider
.divider {
    height: 3px;
    border-radius: 50px;
    background: $secondary;
    width: 60px;
    &.is-centered {
        margin: 0 auto;
    }
}

//Wrapper
.content-wrapper {
    padding: 60px 0;
}


//Pulled image
img.pushed-image {
    margin-top: -29vh;
}

//Icon box
.media.icon-box {
    border-top: none !important;
    .media-content .content p {
        span {
            display: block;
        }
        .icon-box-title {
            color: $blue-grey;
            font-size: 1.2rem;
            font-weight: 600;
        }
        .icon-box-text {
            color: $title-grey;
            font-size: 1rem;
            font-weight: 400;
        }
    }
}