/* ==========================================================================
Main SCSS file / Fresh
========================================================================== */

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('/assets/fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/open-sans-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('/assets/fonts/open-sans-v17-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/open-sans-v17-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

//Imports
@import 'partials/colors';
@import 'partials/navbar';
@import 'partials/dropdowns';
@import 'partials/sections';
@import 'partials/hero';
@import 'partials/footer';
@import 'partials/buttons';
@import 'partials/cards';
@import 'partials/forms';
@import 'partials/animations';
@import 'partials/sidebar';
@import 'partials/testimonials';
@import 'partials/responsive';
@import 'partials/utils';

