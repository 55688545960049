/* ==========================================================================
Fresh Footer
========================================================================== */

footer.footer-white {
    background: $white;
    padding: 35px 0 35px 0;

    .logo {
        padding: 0;
    }

    .footer-logo img {
        height: 50px;
        padding: 0;
        margin: 0;
        vertical-align: bottom;
    }

    a, .copyright {
        font-size: 0.75em;
    }
}
