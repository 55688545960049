/* ==========================================================================
Hero styles
========================================================================== */

.hero-body {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background: url(/assets/images/illustrations/66339.png) no-repeat bottom;
    .title, .subtitle {
        font-family: 'Open Sans', sans-serif;
    }
    .title {
        &.is-bold {
            font-weight: 700;
            color: $secondary;
        }
        line-height: 1.5;
    }
    .subtitle {
        &.is-muted {
            color: $secondary;
        }
        line-height: 1.5;
        margin-bottom: 100px;
    }
}

.hero-foot {
    img.partner-logo {
        height: 70px;
    }
}
